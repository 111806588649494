import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import {
    getIsAnyCreatingNewState,
    getIsShowingWithoutId
} from '../reducers/project-state';
import { setProjectTitle } from '../reducers/project-title';

let messages = defineMessages({
    // 隐藏---修改文件名称
    defaultProjectTitle: {
        id: 'gui.gui.defaultProjectTitle',//'ui',//
        description: 'Default title for project',
        defaultMessage: 'Scratch Project'
    }
});

/* Higher Order Component to get and set the project title
 * @param {React.Component} WrappedComponent component to receive project title related props
 * @returns {React.Component} component with project loading behavior
 */
const TitledHOC = function (WrappedComponent) {
    class TitledComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                qid: ''
            }
        }
        componentDidMount() {
            // this.handgetCgToken(9)
            this.handleReceivedProjectTitle(this.props.projectTitle);
        }
        componentDidUpdate(prevProps) {
            if (this.props.projectTitle !== prevProps.projectTitle) {
                this.handleReceivedProjectTitle(this.props.projectTitle);
            }
            // if project is a new default project, and has loaded,
            if (this.props.isShowingWithoutId && prevProps.isAnyCreatingNewState) {
                // reset title to default
                const defaultProjectTitle = this.handleReceivedProjectTitle();
                this.props.onUpdateProjectTitle(defaultProjectTitle);
            }
            // if the projectTitle hasn't changed, but the reduxProjectTitle
            // HAS changed, we need to report that change to the projectTitle's owner
            if (this.props.reduxProjectTitle !== prevProps.reduxProjectTitle &&
                this.props.reduxProjectTitle !== this.props.projectTitle) {
                this.props.onUpdateProjectTitle(this.props.reduxProjectTitle);
            }
        }
        // handgetCgToken(bsl) {
        //     // debugger
        //     // console.log('0000');
        //     // const url="https://static.educg.net/seartchWeb/static/222.sb3";
        //     // fetch(url,{
        //     //     method: 'GET'
        //     // })
        //     // .then(response=>response.blob())
        //     // .then(blob=>{
        //     //     const reader=new FileReader();
        //     //     reader.onload=()=>this.props.vm.loadProject(reader.result)
        //     //     .then(()=>{
        //     //         GoogleAnalytics.event({
        //     //             category:'project',
        //     //             action:'Import Project File',
        //     //             nonInteraction:true
        //     //         })
        //     //     })
        //     //     reader.readAsArrayBuffer(blob)
        //     // })
        //     // .catch(error=>{
        //     //     alert(`远程加载文件错误！${error}`)
        //     // })
        //     // // window.parent.frames[0].document.addDetail = "新增加的数据";
            
        //     // // console.log(window.parent.frames[0].document.URL)
        //     //  const uu= `https://static.educg.net/seartchWeb/index.html?cgtoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybW9iaWxlIjoiMTM4MDAxMzgwMDAiLCJleHAiOjE2NDI2NzI0NDksInN0YXJ0ZGF0ZSI6IjE2NDI2NjE1Mjk4MzAiLCJ1c2VyZW1haWwiOiJzYW1wbGVUZXN0VXNlckBlZHVjZy5uZXQiLCJkdWVkYXRlIjoiMTY0MjY2Mjg0OTgzMCIsInVzZXJpZCI6InNhbXBsZVRlc3RVc2VyIiwicWlkIjoiMDAxIiwiam9iaWQiOiJtMVowQmJNQ3EyTm15M0hrejE2VWZHQl9TajBvSG5ybyIsInZlcnNpb24iOiIxLjAifQ.EGpL_2DzmGSM03Mqzmrtifmjch8htc1DId5yBdxhhAM`
        //     // const cgtoken = uu.split('=')[1];
        //     // const base64Url = cgtoken.split('.')[1];
        //     // const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        //     // const jsonPayload = decodeURIComponent(
        //     //     atob(base64)
        //     //         .split('')
        //     //         .map(function (c) {
        //     //             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        //     //         })
        //     //         .join('')
        //     // );
        //     // console.log(JSON.parse(jsonPayload));

        //     // const tokenObj = JSON.parse(jsonPayload);

        //     // this.setState({
        //     //     qid: tokenObj.qid,
        //     //     usermobile: tokenObj.usermobile,

        //     // }, () => {
        //     //     console.log(this.state.qid)
        //     //     var autoMessage ={score:100}//this.state;
        //     // window.parent.postMessage(autoMessage,'*')
        //     // });
        //     // console.log(this.state.qid)
        // }
        handleReceivedProjectTitle(requestedTitle) {
 
            let newTitle = requestedTitle;
            if (newTitle === null || typeof newTitle === 'undefined') {
             
                // messages.defaultProjectTitle.defaultMessage='009';

                // messages.defaultProjectTitle.defaultMessage = this.state.qid
                newTitle = this.props.intl.formatMessage(messages.defaultProjectTitle);
            }
            this.props.onChangedProjectTitle(newTitle);
            return newTitle;
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                intl,
                isAnyCreatingNewState,
                isShowingWithoutId,
                onChangedProjectTitle,
                // for children, we replace onUpdateProjectTitle with our own
                onUpdateProjectTitle,
                // we don't pass projectTitle prop to children -- they must use
                // redux value
                projectTitle,
                reduxProjectTitle,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    {...componentProps}
                />
            );
        }
    }

    TitledComponent.propTypes = {
        intl: intlShape,
        isAnyCreatingNewState: PropTypes.bool,
        isShowingWithoutId: PropTypes.bool,
        onChangedProjectTitle: PropTypes.func,
        onUpdateProjectTitle: PropTypes.func,
        projectTitle: PropTypes.string,
        reduxProjectTitle: PropTypes.string
    };

    TitledComponent.defaultProps = {
        onUpdateProjectTitle: () => { }
    };

    const mapStateToProps = state => {
        const loadingState = state.scratchGui.projectState.loadingState;
        return {
            isAnyCreatingNewState: getIsAnyCreatingNewState(loadingState),
            isShowingWithoutId: getIsShowingWithoutId(loadingState),
            reduxProjectTitle: state.scratchGui.projectTitle
        };
    };

    const mapDispatchToProps = dispatch => ({
        onChangedProjectTitle: title => dispatch(setProjectTitle(title))
    });

    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TitledComponent));
};

export {
    TitledHOC as default
};
