export default (filename, blob) => {
  const downloadLink = document.createElement('a');

  document.body.appendChild(downloadLink);
  //设置BLOG转化为文件
  var file = new File([blob], filename, { type: blob.type, lastModifed: Date.now() });
  console.log(file)
  // Use special ms version if available to get it working on Edge.
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }
  let updateurl = '';
  let sendStr = '';
  let ContentType= '';
  var form = new FormData();
  let contType=true;

  // 可以增加表单数据
  form.append("jwt", window.sessionStorage.getItem('jwt'));
  form.append("content", window.sessionStorage.getItem('content'));
  form.append("_id", window.sessionStorage.getItem('_id'));
  form.append('file',file);
  // form.append("file", file);
  if (window.sessionStorage.getItem('mode') == 0) {
    //  存入做题表
    //updateurl ='https://bth.educg.net/scratchapi/saveSelfProject.do'
    updateurl = 'https://fs.api.eduxiji.net/scratchapi/saveSelfProject.do'
    if(JSON.parse(window.sessionStorage.getItem('tabZX'))){
      // 传file
      sendStr=form;
      contType=false;
    }
    else{
      sendStr = `jwt=${window.sessionStorage.getItem('jwt')}&content=${encodeURI(window.sessionStorage.getItem('content'))}&_id=${window.sessionStorage.getItem('_id')}`
      ContentType=`application/x-www-form-urlencoded`;
      contType=true;
    }
    var autoMessage = { score: 0 }//this.state;
    window.parent.postMessage(autoMessage, '*')
  }
  else if (window.sessionStorage.getItem('mode') == 1 || window.sessionStorage.getItem('mode') == 2) {
    // 出题表的接口
    //updateurl = 'https://bth.educg.net/scratchapi/saveProject.do';
    updateurl = 'https://fs.api.eduxiji.net/scratchapi/saveProject.do';// "https://fs.api.eduxiji.net/scratchapi/saveProject.do"// 接口
    sendStr = form;
    contType=false;
    // ContentType=`multipart/form-data`

  }

  // uplodurl定义
  var uplodurl;

  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      //  document.getElementById("demo").innerHTML = this.responseText;
      console.log(JSON.parse(this.responseText))
      const getData = JSON.parse(this.responseText)
      window.sessionStorage.setItem('_id',getData._id)
      alert(getData.message)
    //  增加_id 编辑

      // _id=getData._id        
    }
  };
  xhttp.open("POST", updateurl, true);
  if(contType){
   xhttp.setRequestHeader("Content-type", ContentType);
  }

  // xhttp.send(JSON.stringify(tempdata));
  xhttp.send(sendStr);

  // const url = window.URL.createObjectURL(blob);
  // downloadLink.href = url;
  // downloadLink.download = filename;
  // downloadLink.type = blob.type;
  // downloadLink.click();
  // document.body.removeChild(downloadLink);
  // window.URL.revokeObjectURL(url);
  // if ('download' in HTMLAnchorElement.prototype) {
  //     debugger
  //     const url = window.URL.createObjectURL(blob);
  //     downloadLink.href = url;
  //     downloadLink.download = filename;
  //     downloadLink.type = blob.type;
  //     downloadLink.click();
  //     // remove the link after a timeout to prevent a crash on iOS 13 Safari
  //     window.setTimeout(() => {
  //         document.body.removeChild(downloadLink);
  //         window.URL.revokeObjectURL(url);
  //     }, 1000);
  // } else {
  //     // iOS 12 Safari, open a new page and set href to data-uri
  //     let popup = window.open('', '_blank');
  //     const reader = new FileReader();
  //     reader.onloadend = function () {
  //         popup.location.href = reader.result;
  //         popup = null;
  //     };
  //     reader.readAsDataURL(blob);
  // }

};
