


import styles from './login-modal.css';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../containers/modal.jsx';
import Box from '../box/box.jsx';
import SubmitLoginButton from './submit-login-button.jsx';
import connect from 'react-redux/es/connect/connect';
import { closeLoginModal } from '../../reducers/modals.js';
const LoginModal = props => {
    const {
        question,

} = props;
return (
    <Modal
        className={styles.modalContent}
        contentLabel={props.title}
        id="loginModal"
        onRequestClose={props.onCancel}
    >
        <Box>
            {/* <input
                className={styles.minInput}
                name="account"
                placeholder="账号"
                type="text"
            /><br />
            <input
                className={styles.minInput}
                name="password"
                placeholder="密码"
                type="password"
            /><br /> */}
            <div
                className={styles.boxAll}>
                <div className={styles.boxLeft}>{question}</div>
                <div className={styles.boxRight}>yrtyrtyrtyt</div>

            </div>
            <SubmitLoginButton className={styles.btnSubmit} />
        </Box>
    </Modal>
)
};
LoginModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,

    title: PropTypes.string.isRequired,
    question:  'jnkkkk'
}
const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        console.log('99')
        dispatch(closeLoginModal)
    },


})
export default LoginModal;
